import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { rem } from 'polished';
// import { useTranslation } from 'react-i18next';
import { getSrc } from 'gatsby-plugin-image';

import useLangRedirect from '../hooks/useLangRedirect';

import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Row from '../components/Row';
import Col from '../components/Col';
import Hero from '../components/Hero';
import ArticleItem from '../components/ArticleItem';

import { breakpoint } from '../theme';
import { SiteInformation, PageContent, PostItems } from '../utils/fragments';

const Container = styled.div`
  max-width: ${(props) => props.theme.grid.maxWidth};
  margin: ${rem(0)} auto 0;
  padding-top: ${rem(50)};
  padding-bottom: ${rem(100)};
`;

const PostContent = styled.div`
  font-size: ${rem(16.5)};

  ${breakpoint('large')} {
    font-size: ${rem(18)};
  }

  & .wp-block-image {
    margin-top: ${rem(32)};
    margin-bottom: ${rem(22)};
  }

  & figcaption {
    color: ${(props) => props.theme.palette.colors.fadedText};
    font-size: ${rem(14)};
    font-weight: ${(props) => props.theme.typography.weights.primary.normal};
    font-family: ${(props) => props.theme.typography.fonts.primary};
  }
`;

const ArticlesRow = styled(Row)`
  position: relative;
  padding-top: ${rem(20)};
  padding-bottom: ${rem(40)};

  ${breakpoint('large')} {
    padding-bottom: ${rem(70)};
  }
`;

const ArticleCol = styled(Col)`
  position: relative;
  z-index: 1;
  display: block;
  margin-bottom: 2.5rem;
  width: 100%;

  ${breakpoint('mediumlarge')} {
    width: 33.333%;
  }

  ${breakpoint('large')} {
    width: 33.333%;
  }
`;

const Article = styled(ArticleItem)`
  /* display: block;
  padding: ${(props) => props.theme.spacing.gutter}; */
  width: 100%;
  /* text-align: center; */
`;

function Home({ data }) {
  const { page, articles } = data;
  // const { t } = useTranslation();

  useLangRedirect(page);

  if (!page) {
    return null;
  }

  const {
    featuredImage,
    lang,
    seo,
    pageOptions: { heroSize },
  } = page;

  const featuredMedia = featuredImage?.node;
  const itemImageSrc = getSrc(featuredMedia?.localFile);
  const withHero = true;

  return (
    <Layout headerProps={{ toggleLogoOnStuck: heroSize !== 'default' }}>
      <SEO
        title={seo.title}
        description={seo.metaDesc || seo.opengraphDescription}
        image={itemImageSrc}
        page={page}
        lang={lang}
      />

      {withHero && (
        <Hero
          heading={page.title}
          image={featuredMedia?.localFile}
          pageTitle
          // actions="Actions"
          tint
          big={heroSize === 'big'}
          logo={heroSize === 'big'}
        />
      )}

      <Container>
        {page.content && (
          <Row>
            <Col>
              <PostContent
                dangerouslySetInnerHTML={{ __html: page.content }} // eslint-disable-line react/no-danger
              />
            </Col>
          </Row>
        )}
        <ArticlesRow>
          {articles.edges.map(({ node: article }) => (
            <ArticleCol key={article.id}>
              <Article data={article} />
            </ArticleCol>
          ))}
        </ArticlesRow>
      </Container>
    </Layout>
  );
}

Home.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

Home.fragments = [PageContent, SiteInformation, PostItems];

export const query = graphql`
  query ($id: String!, $lang: String!) {
    page: wpPage(id: { eq: $id }) {
      ...PageContent
    }
    site {
      ...SiteInformation
    }
    articles: allWpPost(
      filter: { lang: { eq: $lang } }
      sort: { fields: date, order: DESC }
    ) {
      ...PostItems
    }
  }
`;

export default Home;
