import { graphql } from 'gatsby';

export const Fragment = graphql`
  fragment PostItems on WpPostConnection {
    edges {
      node {
        id
        featuredImage {
          node {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(
                  width: 850
                  height: 560
                  quality: 75
                  layout: CONSTRAINED
                )
              }
            }
            sourceUrl
            caption
            guid
            id
            description
          }
        }
        content
        excerpt
        title
        slug
        uri
        lang
        author {
          node {
            firstName
            lastName
            avatar {
              url
            }
          }
        }
        categories {
          nodes {
            id
            name
          }
        }
        date
        excerpt
        modified
        tags {
          nodes {
            name
          }
        }
      }
    }
  }
`;

export default Fragment;
